import React, { FC, MouseEvent as ReactMouseEvent } from 'react'
import styled, { css } from 'styled-components'
import RightArrow from 'components/icons/right-arrow'
import theme from 'styles/theme'
import mq from 'styles/mq'
import { Wrapper, ExternalWrapper } from './styled'
import { Link } from 'gatsby'

type Props = {
  to: string
  className?: string
  withAccent?: boolean
  onClick?: (evt: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void
  inverse?: boolean
}

export const StyledLink = styled(Link)<{
  inverse?: boolean
}>`
  color: ${theme.color.link.blue};

  ${({ inverse }) =>
    inverse &&
    css`
      color: ${theme.color.accent.primary};

      &:hover {
        color: #ff8a5c;
      }
    `};
`

export const HeadlineLink = styled(Link)<{
  inverse?: boolean
}>`
  color: ${theme.color.background.dark};
  display: inline-block;

  &:hover {
    color: ${theme.color.background.dark};

    &:after {
      border-color: ${theme.color.background.dark};
    }
  }

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 0;
    border-top: 0.2rem solid ${theme.color.accent.primary};
    margin-top: 0.8rem;
  }

  ${({ inverse }) =>
    inverse &&
    css`
      color: ${theme.color.background.light};

      &:hover {
        color: ${theme.color.background.light};

        &:after {
          border-color: ${theme.color.background.light};
        }
      }
    `};
`

export const ExternalLink: FC<Props> = ({
  children,
  to,
  className = '',
  inverse = false,
  ...props
}) => (
  <ExternalWrapper
    href={to}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
    inverse={inverse}
  >
    {children}
  </ExternalWrapper>
)

export const PrimaryLink: FC<Props> = ({
  children,
  to,
  className = '',
  inverse = false,
  ...props
}) => (
  <Wrapper {...props} to={to} inverse={inverse} className={className}>
    <span>{children}</span>
    <RightArrow />
  </Wrapper>
)

export const SecondaryLink = styled(PrimaryLink)<{
  darkOnHover?: boolean
}>`
  color: ${theme.color.text.light};

  svg {
    g {
      stroke: ${props =>
        props.withAccent ? theme.color.accent.primary : theme.color.text.light};
    }
  }

  ${({ darkOnHover }) =>
    darkOnHover &&
    css`
      &:hover {
        color: ${theme.color.text.dark};

        svg {
          g {
            stroke: ${theme.color.text.dark};
          }
        }
      }
    `}
`

export const TertiaryLink = styled(PrimaryLink)`
  span {
    display: none;

    ${mq.medium} {
      display: inline;
    }
  }

  svg {
    g {
      stroke: currentColor;
    }
  }
`
