import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { heading } from 'styles/typography'
import theme from 'styles/theme'
import Button from 'components/button'

export const Wrapper = styled(Link)<{
  inverse: boolean
}>`
  ${heading.xs};
  display: inline-flex;
  color: ${theme.color.text.dark};

  &:hover {
    color: ${theme.color.accent.primary};
  }

  svg {
    margin-left: 1rem;

    g {
      stroke: ${theme.color.accent.primary};
    }
  }

  ${({ inverse }) =>
    inverse &&
    css`
      color: #fff;

      &:hover {
        color: #ff8a5c;

        svg {
          g {
            stroke: #ff8a5c;
          }
        }
      }
    `}
`

export const ExternalWrapper = styled(Button as any).attrs({ as: Link })<{
  inverse: boolean
}>`
  ${heading.xs};
  display: inline-flex;
  color: ${theme.color.text.light};

  svg {
    margin-left: 1rem;

    g {
      stroke: ${theme.color.accent.primary};
    }
  }

  ${({ inverse }) =>
    inverse &&
    css`
    background: none;
    color: ${theme.color.text.dark};
    box-shadow: 0 0 0 1.5px ${theme.color.text.dark} inset;

    &:hover,
    &:focus {
      color: ${theme.color.text.light};
      // background-color: ${theme.color.accent.primary};
      box-shadow: inset 0 0 0 2em ${theme.color.accent.primary} !important;
    }
  `}
`
