import React from 'react'
import Layout from 'components/layout'
import SEO from 'components/seo'
import WhichTestIsRightForYou from './which-test-is-right-for-you'

const Shop = () => {
  return (
    <Layout headerPosition="fixed">
      <SEO title="Shop" />
      <WhichTestIsRightForYou />
    </Layout>
  )
}

export default Shop
