import styled from 'styled-components'
import ImgBase from 'gatsby-image'
import { StyledLink } from 'components/links'
import theme from 'styles/theme'
import { font, heading, paragraph } from 'styles/typography'
import mq from 'styles/mq'

export const ProductRow = styled.section`
  margin-top: 1.6rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mq.medium} {
    margin-top: 6.4rem;
  }

  ${mq.desktop} {
    margin-top: 0;
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const ProductCard = styled.div`
  background-color: ${theme.color.background.light};
  box-shadow: 0 1rem 2.5rem 0 rgba(0, 0, 0, 0.2);
  width: 100vw;
  margin: 0 0 2rem -6rem;
  padding: 2rem 4rem;
  text-align: center;

  ${mq.medium} {
    width: 80vw;
    margin-left: -2rem;
  }

  ${mq.desktop} {
    width: 40rem;
    margin: 4rem 2rem 0;
  }
`

export const ProductTitle = styled.h4`
  ${heading.xs};
  margin-bottom: 2rem;
`

export const ProductDescription = styled.p`
  ${paragraph.extraSmall};
`

export const Price = styled.p`
  ${heading.xs};
`

export const OldPrice = styled.span`
  color: ${theme.color.text.lightGrey};
  text-decoration: line-through;
`

export const DiscountOffer = styled.span`
  ${heading.xs};
  color: ${theme.color.text.green};
  font-weight: bold;
  padding-bottom: 2.4rem;
`

export const Img = styled(ImgBase)`
  width: 20rem;
  margin: 0 auto 2rem;
`

export const LearnMoreLink = styled(StyledLink as any)`
  ${heading.xs};
  display: block;
  margin-top: 2rem;
`

export const SectionTitle = styled.h1`
  font: bold 2.8rem ${font.headings};
  text-align: center;
  margin-bottom: 0;
`
