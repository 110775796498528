import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Urls from 'common/urls'
import Container from 'components/container'
import AddToCartButton from 'components/add-to-cart-button'
import { Center, Section } from '../styled'
import {
  Img,
  LearnMoreLink,
  Price,
  ProductCard,
  ProductDescription,
  ProductRow,
  ProductTitle,
  SectionTitle,
} from './styled'
import useAllKitProducts from 'hooks/use-all-kit-products'
import { formatCurrency } from 'utils/format'

type QueryResult = {
  kitImage: FluidImage
  kitPlusCryoImage: FluidImage
  vasectomyKitImage: FluidImage
}

const query = graphql`
  query {
    kitImage: file(relativePath: { eq: "kit-top-view.png" }) {
      childImageSharp {
        fluid(maxWidth: 170, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitPlusCryoImage: file(relativePath: { eq: "kit-plus-cryo-top-view.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vasectomyKitImage: file(
      relativePath: { eq: "vasectomy-kit-top-view.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 200, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const WhichTestIsRightForYou = () => {
  const data = useStaticQuery<QueryResult>(query)
  const [
    kitProduct,
    kitPlusCryoProduct,
    vasectomyKitProduct,
  ] = useAllKitProducts()

  return (
    <Section dark>
      <Container>
        <Center>
          <SectionTitle>Which is right for you?</SectionTitle>
          <ProductRow>
            <ProductCard>
              <Img
                // @ts-ignore
                fluid={data.kitImage.childImageSharp.fluid}
              />
              <ProductTitle>Semen Analysis</ProductTitle>
              <ProductDescription>
                The easiest and most trusted mail-in semen analysis.
              </ProductDescription>
              <Price>${formatCurrency(kitProduct.price)}</Price>
              <AddToCartButton
                product={kitProduct}
                CTA="Add to cart"
              >
                Add to cart
              </AddToCartButton>
              <LearnMoreLink to={Urls.kit}>Learn more</LearnMoreLink>
            </ProductCard>
            <ProductCard>
              <Img
                // @ts-ignore
                fluid={data.kitPlusCryoImage.childImageSharp.fluid}
              />
              <ProductTitle>Semen Analysis + Cryopreservation</ProductTitle>
              <ProductDescription>
                Mail-in semen analysis + 3 vials stored to protect your
                fertility. You can use these vials when trying to conceive.
              </ProductDescription>
              <Price>
                ${formatCurrency(kitPlusCryoProduct.price)} + $140/year
              </Price>
              <AddToCartButton
                product={kitPlusCryoProduct}
                CTA="Add to cart"
              >
                Add to cart
              </AddToCartButton>
              <LearnMoreLink to={Urls.kitPlusCryo}>Learn more</LearnMoreLink>
            </ProductCard>
            <ProductCard>
              <Img
                // @ts-ignore
                fluid={data.vasectomyKitImage.childImageSharp.fluid}
              />
              <ProductTitle>Vasectomy Test</ProductTitle>
              <ProductDescription>
                The easiest way to test your vasectomy from home.
              </ProductDescription>
              <Price>${formatCurrency(vasectomyKitProduct.price)}</Price>
              <AddToCartButton
                product={vasectomyKitProduct}
                CTA="Add to cart"
              >
                Add to cart
              </AddToCartButton>
              <LearnMoreLink to={Urls.vasectomyKit}>Learn more</LearnMoreLink>
            </ProductCard>
          </ProductRow>
        </Center>
      </Container>
    </Section>
  )
}

export default WhichTestIsRightForYou
